@import "../../styles/colors.scss";


.layout {
  padding: 0px !important;
  .row{
    align-items: stretch;
    margin:0;
    position: relative;
  }
}
.navbar{
  height: 4rem;
  background: #FFFFFF;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.1);
  position: fixed !important;;
  left: 0;
  right: 0;
  top:0;  

}

.menu-icon{
  color:#ffffff;
  margin-top:20px;
  font-size: 40px;
}

.views{
  margin-left: 4rem;
  margin-top: 4rem;
  padding-right: 0!important;
}

.sc-modal-container {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.sc-modal-container--open {
  display: block;
}

.sc-modal {
  position: relative;
  background-color: #ffffff;
  border-radius: 3px;
  margin: 20px auto 20px auto;
  border: 1px solid #888;
  max-width: 500px;
  width: 100%;
}

.sc-modal__header, .sc-modal__body, .sc-modal__footer {
  padding: 15px 10px;
}

.sc-modal__header {
  border-bottom: 1px solid #e1e4e8;
}

.sc-modal__title {
  font-size: 18px;
}

.sc-modal__progress {
  margin: 10px 0px;
  width: 100%;
  height: 20px;
  border-radius: 3px;
  background-color: #e9ecef;
}

.sc-modal__progress-bar {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  background-color: #F28E2A;
  transition: width .5s;
}

.sc-modal__timer-message{
  text-align: right;
}

.sc-modal__footer {
  text-align: right;
  border-top: 1px solid #e1e4e8;
}

.sc-modal__button {
  margin-left: 5px;
  border-radius: 3px;
  padding: 5px 15px;
  border: none;
  color: #fff;
}

.sc-modal__button--logout {
  font-family: "Gotham Bold", sans-serif;
  background-color: $first_color !important;
  border-color: $first_color !important;
  text-transform: uppercase;
}

.sc-modal__button--logout:hover {
  background-color: $first_color !important;
  border-color: #F28E2A;
}

.sc-modal__button--logout:focus {
  box-shadow: 0 0 0 0.2rem #F28E2A;
}

.sc-modal__button--continue {
  font-family: "Gotham Bold", sans-serif;
  text-transform: uppercase;
  background-color: #fff !important;
  color: $first_color !important;
  border: 2px solid $first_color !important;
}

.sc-modal__button--continue:hover {
  background-color: $first_color !important;
}

.sc-modal__button--continue:focus {
  box-shadow: 0 0 0 0.2rem #fff;
}

.sc-modal--open {
  animation-name: animate;
  animation-duration: 0.4s;
}

@keyframes animate {
  from {
    top: -300px;
    opacity: 0
  }
  to {
    top: 0;
    opacity: 1
  }
}