.greenCircle {
    display:flex;
    justify-content: center;
    background: green;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    text-align: center;
    align-items: center;
    color:white;
    font-size: 18px;
}

.redCircle {
    display:flex;
    justify-content: center;
    background: red;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    text-align: center;
    align-items: center;
    color:white;
    font-size: 18px;
}

.textResult{
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 15rem;
}

.btnConfirmar button{
    width: 30%;
}

.btnConfirmar{
    margin-top: 4rem;
    align-items: center;
    justify-content: center;
    display: flex;
}