
$first_color: #F28E2A;
$second_color: #2C3E60;
$third_color: #fffffe;
$fourth_color: #22324e;
$fifth_color: #054B62;
$sixth_color: #e75459;
$seventh_color: #BAB5B5;
$eighth_color: #e3e3e3;
$nineth_color: #f9f9f9;
$tenth_color: #ffffff;
$eleventh_color: #c5c5c5;
$twelfth_color: #cbcbcb;
$thirteen_color: #F9E7D5;
$text_color: #80848a;
$text_color_dark: #22324e;
$background_layout: #eeeff4;
$link_login: #36a0ce;
$disabled_color: #D9D4D4;
$borders-color: #9A9A9A,
