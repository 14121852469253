@import "../../styles/colors.scss";

.text-input {
  .form-label {
    font-weight: bold;
    white-space: nowrap;
  }
  .text-muted {
    text-align: center;
  }

  .textInput {
    border-style: solid;
    border-right-color: #d9d4d4;
    border-top-color: #d9d4d4;
    border-bottom-color: #d9d4d4;
    border-radius: 0.4rem;
    &:focus{
      box-shadow: none;
      border-color:  $first_color;

    }

    padding: 0.5rem;
    display: inline-block !important;
    width: 100%;
  }
  // .icon-container-input {
  //   display: inline;
  //   border: 1px;
  //   border-style: solid;
  //   border-left-color: #d9d4d4;
  //   border-top-color: #d9d4d4;
  //   border-bottom-color: #d9d4d4;
  //   border-right-color: transparent;
  //   border-radius: 0.4rem 0 0 0.4rem;
  //   padding: 0.8rem 0.6rem 0.6rem 0.6rem;
  // }

}
