.status-circle {
  display: inline-block;
  min-width: 40px;
  height: 40px;
  padding-top: 8px !important;
  border-radius: 2rem;
  span {
    color: #fff;
    font-weight: bold;
  }
}
