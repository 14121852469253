@import "../../styles/colors.scss";

.register-form {
  .editIcon {
    cursor: pointer;
  }
  .accountsTable tbody tr:hover td {
    background: rgba(242, 142, 42, 0.15);
    border: 0px;
  }
  .accountsTable thead tr th {
    background: #c4c4c4;
    border: 0px;
  }
  .validationErrors {
    margin: -0.5rem 0rem 0.5rem 0.5rem;
  }
  .selectedToEdit {
    background: rgba(242, 142, 42, 0.15);
  }
  label {
    display: block;
  }
}
