@import "../../../../styles/colors.scss";
.navbar {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-weight: bold;
  box-shadow: 2px 2px 9px rgba(0, 0, 0, 0.1);
  position:fixed;
  z-index: 1;
  .col {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logo-brand {
    margin:0;
    object-fit: contain;
    height: 38px;
    padding: 0;
  }

  .dropdown{
    img{
      margin:0;
      object-fit: contain;
      height: 10px;
      padding: 0;
    }
    .dropdown-toggle::after{
      display: none;
    }
  }
  .badg {
    padding: .8rem;
  }
  .link-nav{
    text-decoration: none;
  }
  .user-circle{
    background-color: black;
    color:white;
    padding:6px;
    border-radius: 50%;
    width: 28px;
    height: 28px;
  }
  .question-circle{
    color: white;
    background-color:#9A9A9A;
    border: #9A9A9A 1px solid;
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }
  .cog-circle{
    color: #9A9A9A;
    
    
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }
  h6{
    margin-top:0.7rem;
    margin-left: 0.725rem;
    margin-right: 0.725rem;
    font-weight: bold;
    font-size: 11px;
  }
  .dropdown-menu{
    left:-125px;
    top:62px;
  }
}
