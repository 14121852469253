@import "../../styles/colors.scss";

.custom-checkbox {
  padding: 0.5rem !important;
  -webkit-appearance: none;
  background-color: rgb(248, 241, 241);
  border: 1px solid #b7bbbb;
  //   box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
  //     inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  border-radius: 3px;
  display: inline-block;
  position: relative;

  &:checked {
    background-color: $first_color;
    border: none;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
      inset 15px 10px -12px rgba(255, 255, 255, 0.1);
    color: #99a1a7;
  }
  &:disabled {
    background-color: rgb(223, 213, 213);
    font-weight: bold;
  }

  &:checked:after {
    content: "\2714";
    font-size: 3px;
    position: absolute;
    bottom: 0.1px;
    left: 4px;
    color: #fff;
  }

  &:checked:after {
    font-size: 10px;
    //left: 6px;
  }

  cursor: pointer;
}
