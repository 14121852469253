@import "../../styles/colors.scss";

.action-button{
    align-self: inherit;
    margin: 0.5rem;
    text-align: center;
   
}

.location-list{
    border: 1px solid black;
    padding-bottom: 3rem;
    box-shadow: 0px 4px 0px grey;
    max-height: 30vh;
    overflow-y: scroll;
    white-space: nowrap;
    overflow-x: scroll;
}
.location-list-item{
    width: 100%;

}
@media screen and (min-width: 580px) and (max-width:1430px) {
    .location-list-item{
        width: 150% !important;
    
    }
}

.location-list-item:hover {
    background-color: $second_color !important;
    color: $third_color
}
.location-list-item:active {
    background-color: $second_color;
    color: $third_color
}

.table th, .table td {
    text-transform: uppercase;
    color: #212529;
    
}
.table th {
   font-family: "Gotham Light", sans-serif;
}
.table td {
    font-family: "Gotham Medium", sans-serif;
}
.secondary-action-button {
    margin-left: 2.5%;
}

.th-custom{
    white-space: nowrap;
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
form ::placeholder, form select{
    color: black !important;
    padding: 0.5rem;
    width: 50%;
}
.select {
background-color:  $thirteen_color; 
}

/* .col-table {
   max-height: 40vh;
   overflow-y: scroll;
} */
.select-all {
    font-family: "Gotham Bold", sans-serif;
    font-size: 0.8rem;
    color: white;  
}
.check-all {
    text-align: center;
    margin-left: 0.5rem;
    margin-top: 0.7rem;
    padding: 2rem;
}
.show-modal {
    display: block;
    position: absolute;
    top:0rem;
    left:2.5rem;
    border: 1px solid $second_color;
    text-align: center;
    padding: 0.5rem;
    margin-left:0.5rem;
}
.hide-modal {
    display: none;
}